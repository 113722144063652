/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials/content/dropdown/Dropdown1'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store'

type Props = {
  className: string
}

const Highlights: React.FC<Props> = ({ className }) => {
  const dashboardData = useSelector(
    (state: RootState) => state.dailyReport.DailyReportList?.data?.todayReport || []
)
 console.log(dashboardData)
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Highlights</h3>
        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
        </div> */}
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-40px bg-success'></span>
          {/* end::Bullet */}
          {/* begin::Checkbox */}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            {/* <input className='form-check-input' type='checkbox' value='' /> */}
          </div>
          {/* end::Checkbox */}
          {/* begin::Description */}
          <div className='flex-grow-1'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Punch In Time
            </a>
            <span className='text-muted fw-semibold d-block'>Today</span>
          </div>
          {/* end::Description */}
          <span className='badge badge-light-success fs-8 fw-bold'>{dashboardData?.initial_in ?? "-"}</span>
        </div>
        {/* end:Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-40px bg-primary'></span>
          {/* end::Bullet */}
          {/* begin::Checkbox */}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            {/* <input className='form-check-input' type='checkbox' value='' /> */}
          </div>
          {/* end::Checkbox */}
          {/* begin::Description */}
          <div className='flex-grow-1'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Punch Out Time
            </a>
            <span className='text-muted fw-semibold d-block'>Today</span>
          </div>
          {/* end::Description */}
          <span className='badge badge-light-primary fs-8 fw-bold'>{dashboardData?.final_out?? "-"}</span>
        </div>
        {/* end:Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-40px bg-warning'></span>
          {/* end::Bullet */}
          {/* begin::Checkbox */}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            {/* <input className='form-check-input' type='checkbox' value='' /> */}
          </div>
          {/* end::Checkbox */}
          {/* begin::Description */}
          <div className='flex-grow-1'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Total Exemption Given
            </a>
            <span className='text-muted fw-semibold d-block'>Month</span>
          </div>
          {/* end::Description */}
          <span className='badge badge-light-warning fs-8 fw-bold'>{dashboardData?.total_exemption_given} Minutes</span>
        </div>
        {/* end:Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-40px bg-primary'></span>
          {/* end::Bullet */}
          {/* begin::Checkbox */}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            {/* <input className='form-check-input' type='checkbox' value='' /> */}
          </div>
          {/* end::Checkbox */}
          {/* begin::Description */}
          <div className='flex-grow-1'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Total Working Days
            </a>
            <span className='text-muted fw-semibold d-block'>Month</span>
          </div>
          {/* end::Description */}
          <span className='badge badge-light-primary fs-8 fw-bold'>{dashboardData?.total_working_days_on} days</span>
        </div>
        {/* end:Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-40px bg-danger'></span>
          {/* end::Bullet */}
          {/* begin::Checkbox */}
          <div className='form-check form-check-custom form-check-solid mx-5'>
            {/* <input className='form-check-input' type='checkbox' value='' /> */}
          </div>
          {/* end::Checkbox */}
          {/* begin::Description */}
          <div className='flex-grow-1'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
              Total Attendance
            </a>
            <span className='text-muted fw-semibold d-block'>Month</span>
          </div>
          {/* end::Description */}
          <span className='badge badge-light-danger fs-8 fw-bold'>{dashboardData?.total_attendance_on?? "-"} days</span>
        </div>
        {/* end:Item */}
        
      </div>

      {/* end::Body */}
    </div>
  )
}

export { Highlights }
