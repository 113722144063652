import {Suspense, useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {GoogleOAuthProvider} from '@react-oauth/google'
import {GoogleLogin} from '@react-oauth/google'
import {jwtDecode} from 'jwt-decode'
import {ThemeProvider} from '../_metronic/partials/layout/app-theme/ThemeContext'
import {ThemeModeProvider} from '../_metronic/partials'
import {useDispatch} from 'react-redux'
import {getCurrentUser, getUserSettingApi} from './redux/slices/AuthSlices'

const App = () => {
  const dispatch = useDispatch<any>()

  const fetchCurrentUser = async () => {
    dispatch(getCurrentUser())
    dispatch(getUserSettingApi())
  }

  useEffect(() => {
    fetchCurrentUser()
  }, [])
  return (
    <GoogleOAuthProvider clientId='606635690321-i76va0umlkobcd75t20vloaieq6iccj5.apps.googleusercontent.com'>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <ThemeModeProvider>
            <LayoutProvider>
              <ThemeProvider>
                <AuthInit>
                  <ToastContainer />
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </ThemeProvider>
            </LayoutProvider>
          </ThemeModeProvider>
        </I18nProvider>
      </Suspense>
    </GoogleOAuthProvider>
  )
}

export {App}
