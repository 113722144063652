import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {
  getDailyReportList,
  getDailyReportById,
  deleteDailyReport,
  createDailyReport,
  updateDailyReport,
} from '../../services/DailyReportService';

interface GetDailyReportListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface DailyReportState {
  DailyReportList: any
  DailyReportData: any
  createDailyReportData: any
  updateDailyReportData: any
  loading: {
    dailyReportList: boolean
    DailyReportData: boolean
    createDailyReport: boolean
    updateDailyReport: boolean
  }
}

const initialState: DailyReportState = {
  DailyReportList: null,
  DailyReportData: null,
  createDailyReportData: null,
  updateDailyReportData: null,
  loading: {
    dailyReportList: false,
    DailyReportData: false,
    createDailyReport: false,
    updateDailyReport: false,
    },
}

export const createDailyReportApi = createAsyncThunk(
  'create-workflow/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createDailyReport(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getDailyReportListApi = createAsyncThunk(
  'DailyReportList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetDailyReportListParams,
    {rejectWithValue}
  ) => {
    try {
      const result = await getDailyReportList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const getDailyReportByIdApi = createAsyncThunk(
  'DailyReportById/api/get',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await getDailyReportById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateDailyReportApi = createAsyncThunk(
  'updateDailyReport/api/put',
  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateDailyReport(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteDailyReportApi = createAsyncThunk(
  'DailyReport/api/delete',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteDailyReport(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



const DailyReportSlice = createSlice({
  name: 'DailyReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getDailyReportListApi
      .addCase(getDailyReportListApi.pending, (state) => {
        state.loading.dailyReportList = true
      })
      .addCase(getDailyReportListApi.fulfilled, (state, action) => {
        state.DailyReportList = action.payload
        state.loading.dailyReportList = false
      })
      .addCase(getDailyReportListApi.rejected, (state) => {
        state.loading.dailyReportList = false
      })

      // getDailyReportByIdApi
      .addCase(getDailyReportByIdApi.pending, (state) => {
        state.loading.DailyReportData = true
      })
      .addCase(getDailyReportByIdApi.fulfilled, (state, action) => {
        state.DailyReportData = action.payload
        state.loading.DailyReportData = false
      })
      .addCase(getDailyReportByIdApi.rejected, (state) => {
        state.loading.DailyReportData = false
      })

      // createDailyReportApi
      .addCase(createDailyReportApi.pending, (state) => {
        state.loading.createDailyReport = true
      })
      .addCase(createDailyReportApi.fulfilled, (state, action) => {
        state.createDailyReportData = action.payload
        state.loading.createDailyReport = false
      })
      .addCase(createDailyReportApi.rejected, (state) => {
        state.loading.createDailyReport = false
      })

      // updateDailyReportApi
      .addCase(updateDailyReportApi.pending, (state) => {
        state.loading.updateDailyReport = true
      })
      .addCase(updateDailyReportApi.fulfilled, (state, action) => {
        state.updateDailyReportData = action.payload
        state.loading.updateDailyReport = false
      })
      .addCase(updateDailyReportApi.rejected, (state) => {
        state.loading.updateDailyReport = false
      })

      // deleteDailyReportApi
      .addCase(deleteDailyReportApi.pending, (state) => {
        state.loading.updateDailyReport = true
      })
      .addCase(deleteDailyReportApi.fulfilled, (state, action) => {
        state.updateDailyReportData = action.payload
        state.loading.updateDailyReport = false
      })
      .addCase(deleteDailyReportApi.rejected, (state) => {
        state.loading.updateDailyReport = false
      })

  },
})

export default DailyReportSlice
