import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {
  createSalaryComponentType,
  deleteSalaryComponentType,
  getSalaryComponentTypeById,
  getSalaryComponentTypeList,
  updateSalaryComponentType, // import the update API
} from '../../services/SalaryComponentTypeService'

interface SalaryComponentTypeState {
  salaryComponentTypeDetailsList: any | null
  salaryComponentTypeDetails: any | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: SalaryComponentTypeState = {
  salaryComponentTypeDetailsList: null,
  salaryComponentTypeDetails: null,
  status: 'idle',
  error: null,
}

interface GetListParams {
  pageNumber?: number
  pageSize?: number
  filterkey?: any
  isDropdown?: number
  salarySectionId?: number
}

interface GetSalaryComponentTypeByIdParams {
  id: string
}

interface UpdateSalaryComponentTypeParams {
  id: string | undefined
  data: any
}

// Thunks
export const getSalaryComponentTypeListApi = createAsyncThunk(
  'salaryComponentType/getSalaryComponentTypeList',
  async (
    {pageNumber, pageSize, filterkey, isDropdown = 0, salarySectionId}: GetListParams,
    {rejectWithValue}
  ) => {
    try {
      const result = await getSalaryComponentTypeList(
        pageNumber,
        pageSize,
        filterkey,
        isDropdown,
        salarySectionId
      )
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const createSalaryComponentTypeApi = createAsyncThunk(
  'salaryComponentType/createSalaryComponentType',
  async (salaryComponentTypeData: any, {rejectWithValue}) => {
    try {
      const result = await createSalaryComponentType(salaryComponentTypeData)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to create Leave Policy')
    }
  }
)

export const getSalaryComponentTypeByIdApi = createAsyncThunk(
  'salaryComponentType/getSalaryComponentTypeById',
  async ({id}: GetSalaryComponentTypeByIdParams, {rejectWithValue}) => {
    try {
      const result = await getSalaryComponentTypeById(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Leave Policy not found')
    }
  }
)

// Update SalaryComponentType
export const updateSalaryComponentTypeApi = createAsyncThunk(
  'salaryComponentType/updateSalaryComponentType',
  async ({id, data}: UpdateSalaryComponentTypeParams, {rejectWithValue}) => {
    try {
      const result = await updateSalaryComponentType(id, data)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to update Leave Policy')
    }
  }
)

export const deleteSalaryComponentTypeApi = createAsyncThunk(
  'salaryComponentType/deleteSalaryComponentTypeApi',
  async (id: any, {rejectWithValue}) => {
    console.log('Delete Leave', id)
    try {
      const result = await deleteSalaryComponentType(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to delete Leave Policy')
    }
  }
)

// Slice
const SalaryComponentTypeSlice = createSlice({
  name: 'salaryComponentType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get SalaryComponentType List
      .addCase(getSalaryComponentTypeListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getSalaryComponentTypeListApi.fulfilled, (state, action) => {
        state.salaryComponentTypeDetailsList = action.payload
        state.status = 'succeeded'
      })
      .addCase(getSalaryComponentTypeListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Create SalaryComponentType
      .addCase(createSalaryComponentTypeApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(createSalaryComponentTypeApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(createSalaryComponentTypeApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Get SalaryComponentType By ID
      .addCase(getSalaryComponentTypeByIdApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getSalaryComponentTypeByIdApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded'
        state.salaryComponentTypeDetails = action.payload
      })
      .addCase(getSalaryComponentTypeByIdApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Update SalaryComponentType
      .addCase(updateSalaryComponentTypeApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(updateSalaryComponentTypeApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(updateSalaryComponentTypeApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Delete SalaryComponentType
      .addCase(deleteSalaryComponentTypeApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(deleteSalaryComponentTypeApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(deleteSalaryComponentTypeApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export default SalaryComponentTypeSlice
