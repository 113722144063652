import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {
  createLeavePolicy,
  deleteLeavePolicy,
  getLeavePolicyById,
  getLeavePolicyList,
  getLeavePolicyRoaster,
  postLeavePolicyRoaster,
  updateLeavePolicy, // import the update API
} from '../../services/LeavePolicyService'

interface LeavePolicyState {
  leavePolicyDetailsList: any | null
  leavePolicyDetails: any | null
  leaveRoasterList:any
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: LeavePolicyState = {
  leavePolicyDetailsList: null,
  leavePolicyDetails: null,
  leaveRoasterList: [],
  status: 'idle',
  error: null,
}

interface GetListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface GetLeavePolicyByIdParams {
  id: string
}

interface UpdateLeavePolicyParams {
  id: string | undefined
  data: any
}

// Thunks
export const getLeavePolicyListApi = createAsyncThunk(
  'leavePolicy/getLeavePolicyList',
  async ({pageNumber, pageSize, filterkey, isDropdown = 0}: GetListParams, {rejectWithValue}) => {
    try {
      const result = await getLeavePolicyList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const getLeaveTemplateApi = createAsyncThunk(
  'leavePolicy/getLeavePolicyRoaster',
  async ({pageNumber, pageSize, filterkey, isDropdown = 0}: GetListParams, {rejectWithValue}) => {
    try {
      const result = await getLeavePolicyRoaster(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const postLeaveTemplateApi = createAsyncThunk(
  'post/leavePolicy/getLeavePolicyRoaster',
  async ({payload,id}:{payload:any,id:any}, {rejectWithValue}) => {
    try {
      const result = await postLeavePolicyRoaster(payload,id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)


export const createLeavePolicyApi = createAsyncThunk(
  'leavePolicy/createLeavePolicy',
  async (leavePolicyData: any, {rejectWithValue}) => {
    try {
      const result = await createLeavePolicy(leavePolicyData)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to create Leave Policy')
    }
  }
)

export const getLeavePolicyByIdApi = createAsyncThunk(
  'leavePolicy/getLeavePolicyById',
  async ({id}: GetLeavePolicyByIdParams, {rejectWithValue}) => {
    try {
      const result = await getLeavePolicyById(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Leave Policy not found')
    }
  }
)

// Update LeavePolicy
export const updateLeavePolicyApi = createAsyncThunk(
  'leavePolicy/updateLeavePolicy',
  async ({id, data}: UpdateLeavePolicyParams, {rejectWithValue}) => {
    try {
      const result = await updateLeavePolicy(id, data)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to update Leave Policy')
    }
  }
)

export const deleteLeavePolicyApi = createAsyncThunk(
  'leavePolicy/deleteLeavePolicyApi',
  async (id: any, {rejectWithValue}) => {
    console.log('Delete Leave', id)
    try {
      const result = await deleteLeavePolicy(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to delete Leave Policy')
    }
  }
)

// Slice
const LeavePolicySlice = createSlice({
  name: 'leavePolicy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get LeavePolicy List
      .addCase(getLeavePolicyListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getLeavePolicyListApi.fulfilled, (state, action) => {
        state.leavePolicyDetailsList = action.payload
        state.status = 'succeeded'
      })
      .addCase(getLeavePolicyListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Create LeavePolicy
      .addCase(createLeavePolicyApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(createLeavePolicyApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(createLeavePolicyApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Get LeavePolicy By ID
      .addCase(getLeavePolicyByIdApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getLeavePolicyByIdApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded'
        state.leavePolicyDetails = action.payload
      })
      .addCase(getLeavePolicyByIdApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Update LeavePolicy
      .addCase(updateLeavePolicyApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(updateLeavePolicyApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(updateLeavePolicyApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })


      // Roaster List LeavePolicy
      .addCase(getLeaveTemplateApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getLeaveTemplateApi.fulfilled, (state,action) => {
        state.status = 'succeeded'
        state.leaveRoasterList = action.payload
      })
      .addCase(getLeaveTemplateApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Delete LeavePolicy
      .addCase(deleteLeavePolicyApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(deleteLeavePolicyApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(deleteLeavePolicyApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export default LeavePolicySlice
