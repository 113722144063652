/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials/content/dropdown/Dropdown1'
import { TablesWidget } from '../../../_metronic/partials/widgets'
import { useDispatch, useSelector } from 'react-redux'
import { getDailyReportListApi } from '../../redux/slices/DailyReportSlices'
import { RootState } from '../../redux/store'
import { DateFormat } from '../../../_metronic/helpers/components/DateFormat'
import Loader from '../../../_metronic/layout/components/loader/Loader'
import { Link } from 'react-router-dom'

type Props = {
    className: string
}

const EmployeeAttendance: React.FC<Props> = ({ className }) => {
    const [toggleMenu, setToggleMenu] = useState(false);


    let dashboardData = useSelector((state: any) => state?.dahsboard?.attendance?.data?.data)

    dashboardData = {
        "daily_attendance":
            [
                { "date": "2024-05-03", "initial_in": "23:00", "final_out": "11:00", "total_working_hour": "9:00", "overtime_hours": "2:00", "lateby": "00:20", "give_exemption": "00:20" }
            ]
    };

    const dispatch = useDispatch<any>()

    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchKey, setSearchKey] = useState('')
    const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
    const [isLoading, setLoading] = useState<boolean>(false)

    const fetchDailyReportList = useCallback(() => {
        setLoading(true)

        dispatch(
            getDailyReportListApi({
                pageNumber: currentPage,
                pageSize: rowsPerPage,
                filterkey: debouncedSearchQuery,
                isDropdown: 0,
            })
        )
            .then((value: any) => {
                setLoading(false)
            })
            .catch((error: any) => {
                setLoading(false)
            })
    }, [dispatch, currentPage, rowsPerPage, debouncedSearchQuery])

    useEffect(() => {
        fetchDailyReportList()
    }, [fetchDailyReportList, currentPage, rowsPerPage, debouncedSearchQuery])

    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebouncedSearchQuery(searchKey)
        }, 1000)
        return () => clearTimeout(debounceTimeout)
    }, [searchKey])

    const DailyReportList = useSelector(
        (state: RootState) => state.dailyReport.DailyReportList?.data?.data || []
    )

    const TotalData = useSelector(
        (state: RootState) => state.dailyReport.DailyReportList?.data?.total || 0
    )


    console.log(DailyReportList)

    return (
        <div className={`card ${className}`}>
            {/* begin::Header */}
            <div className='card-header border-0 py-5'>
                <h3 className='card-title align-items-start flex-column'>
                    <span className='card-label fw-bold fs-3 mb-1'>Attendance</span>
                    <span className='text-muted fw-semibold fs-7'>Recent entries</span>
                </h3>

                {/* <div className='card-toolbar'>
                    <button
                        type='button'
                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTIcon iconName='category' className='fs-2' />
                    </button>
                    <Dropdown1 />
                </div> */}
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className=''>
                {/* begin::Table container */}
                <div className='table-responsive'>
                    {/* begin::Table */}
                    {DailyReportList?.length > 0 ?
                        <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                            {/* begin::Table head */}
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    <th className='w-25px'>

                                    </th>
                                    <th className='min-w-140px'>In Date</th>
                                    <th className='min-w-140px'>Out Date</th>
                                    <th className='min-w-140px'>Emp In Time</th>
                                    <th className='min-w-120px'>Emp Out Time</th>
                                    <th className='min-w-120px'>Working Hours</th>
                                    <th className='min-w-120px'>Overtime Hours</th>
                                    <th className='min-w-120px'>Late By</th>
                                    <th className='min-w-100px'>Excemption</th>
                                </tr>
                            </thead>
                            {/* end::Table head */}
                            {/* begin::Table body */}
                            <tbody>
                                {DailyReportList?.map((item: any) =>
                                    <tr>
                                        <td>

                                        </td>

                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                {DateFormat(item.in_date) ?? "-"}
                                            </a>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                                                {DateFormat(item.out_date) ?? "-"}
                                            </a>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                {item.initial_in ?? "-"}
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>On Time</span>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                {item.final_out ?? "-"}
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>On Time</span>
                                        </td>
                                        <td>
                                            <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                                                {item.total_working_hour ?? "-"}
                                            </a>
                                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                                Great
                                            </span>
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>{item?.overtime_hours ?? "-"}</td>
                                        <td>
                                            <span className='badge badge-light-danger'>{item?.lateby ?? "-"}</span>
                                        </td>
                                        <td className='text-dark fw-bold text-hover-primary fs-6'>
                                            <span className='badge badge-light-danger'>{item?.give_exemption ? "Yes" : "No"}</span>
                                        </td>
                                    </tr>
                                )

                                }
                            </tbody>
                            {/* end::Table body */}
                        </table>
                        :
                        isLoading ? <Loader /> : <div className='text-center w-100 my-10'>
                            <span className='badge badge-light-danger p-5 fs-6'>No entries found</span>
                        </div>
                    }
                    {/* <div className='text-center w-100 my-10'>
                        <Link to={"/hrm/attendance-report"} >
                            <span className='badge badge-light-success p-5 fs-6'>View All</span>
                        </Link>
                    </div> */}
                    {/* end::Table */}
                </div>
                {/* end::Table container */}

                {/* <TablesWidget
          data={[{"name":"","email":"","number":""}]}
          columns={columnData}
          // isLoading={loading}
          // handlePerRowsChange={handlePerRowsChange}
          // handlePageChange={handlePageChange}
          // totalRows={TotalData}
          showPagination={true}
          onRowClicked={(row, e) => { console.log(row) }}
          selectableRows
          // onSelectedRowsChange={(selected) => { setSelectedRow(selected.selectedRows) }}
        /> */}
                {/* begin::Table container */}
                {/* end::Table container */}

            </div>
            {/* begin::Body */}
        </div>
    )
}

export { EmployeeAttendance }
