import instance from './instance'

export const createDepartment = (payload: any) => instance.post('modules-hrm/department', payload)

export const getDepartmentList = (
  page?: number,
  pageSize?: number,
  filterkey?: any,
  isDropdown?: any
) => {
  // Build query parameters dynamically based on the arguments
  const queryParams = new URLSearchParams()

  if (page !== undefined) {
    queryParams.append('page', page.toString())
  }
  if (pageSize !== undefined) {
    queryParams.append('pageSize', pageSize.toString())
  }
  if (filterkey) {
    queryParams.append('filterkey', filterkey)
  }
  if (isDropdown !== undefined) {
    queryParams.append('is_dropdown', isDropdown.toString())
  }

  return instance.get(`modules-hrm/departments?${queryParams.toString()}`)
}

export const updateDepartment = (id: any, payload: any) =>
  instance.post(`modules-hrm/department/${id}`, payload)

export const deleteDepartment = (id: any) => instance.delete(`modules-hrm/department/${id}`)

export const getDepartmentById = (id: number) => instance.get(`modules-hrm/department/${id}`)
