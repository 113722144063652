import instance from './instance'
const roasterUrl = 'modules-hrm/roasters'

export const getRoasterList = (
  page: number,
  pageSize: number,
  filterkey: any,
  isDropdown: number
) =>
  instance.get(
    `${roasterUrl}?page=${page}&pageSize=${pageSize}&organization_location_id=${filterkey.organization_location_id}&department_id=${filterkey?.department_id}&designation_id=${filterkey?.designation_id}&gender_id=${filterkey?.gender_id}&isDropdown=${isDropdown}`
  )

export const getRoasterById = (id: number) => instance.get(`${roasterUrl}/${id}`)

export const createRoaster = (payload: any) => instance.post(roasterUrl, payload)
