import React, {useEffect, useState} from 'react'
import './ComponentStyles.css'

interface ComponentDetailFormProps {
  formik: any
  componentTypeSelector: any
}

const ComponentListForm: React.FC<ComponentDetailFormProps> = ({formik, componentTypeSelector}) => {
  const [selectedCalculationType, setSelectedCalculationType] = useState<any | null>(null)
  const handleCalculationTypeClick = (key: string) => {
    const selectedType = formik.values.configuration_json.find((calc: any) => calc.key === key)
    setSelectedCalculationType(selectedType || null)
  }

  const handleCheckboxChange = (configKey: string, field: string, value: boolean) => {
    const calcTypeIndex = formik.values.configuration_json.findIndex(
      (calc: any) => calc.key === selectedCalculationType.key
    )

    if (calcTypeIndex !== -1) {
      const updatedCalculationTypes = [...formik.values.configuration_json]
      const updatedConfig = updatedCalculationTypes[calcTypeIndex].config.map((config: any) =>
        config.key === configKey ? {...config, [field]: value} : config
      )

      updatedCalculationTypes[calcTypeIndex] = {
        ...updatedCalculationTypes[calcTypeIndex],
        config: updatedConfig,
      }

      formik.setFieldValue('configuration_json', updatedCalculationTypes)
      setSelectedCalculationType(updatedCalculationTypes[calcTypeIndex])
    }
  }
  useEffect(() => {
    // Log the current calculation types for debugging

    if (formik.values.configuration_json.length > 0) {
      // If the selected calculation type is not in the updated list or is null
      if (
        !selectedCalculationType ||
        !formik.values.configuration_json.some(
          (calc: any) => calc.key === selectedCalculationType.key
        )
      ) {
        // Set the first calculation type as the selected one
        setSelectedCalculationType(formik.values.configuration_json[0])
      }
    } else {
      // Reset selection if there are no calculation types left
      setSelectedCalculationType(null)
    }
  }, [formik.values.configuration_json, selectedCalculationType])

  return (
    <div className='py-4'>
      {/* Card */}
      <div className='card border rounded'>
        <div className='card-header bg-light d-flex justify-content-start align-items-center py-2'>
          {formik.values.configuration_json.map((cT: any) => {
            const sectionType = componentTypeSelector.find(
              (type: any) => type.id === formik.values.salary_section_id
            )

            const matchedType = sectionType.calculation_type.find(
              (type: any) => type.key === cT.key
            )
            const label = matchedType ? matchedType.label : cT.key
            return (
              <button
                type='button'
                className={`btn mx-1 ${
                  selectedCalculationType?.key === cT.key
                    ? 'btn-primary text-white'
                    : 'btn-outline-secondary'
                }`}
                key={cT.key}
                onClick={() => handleCalculationTypeClick(cT.key)}
                style={{
                  fontSize: '14px',
                  fontWeight: selectedCalculationType?.key === cT.key ? 'bold' : 'normal',
                }}
              >
                {label}
              </button>
            )
          })}
        </div>

        {/* Body */}
        <div className='card-body'>
          {/* Table Header */}
          <div className='row mb-3 fw-bold text-muted border-bottom pb-2'>
            <div className='col-6'>Configuration Key</div>
            <div className='col-2 text-center'>Editable</div>
            <div className='col-2 text-center'>Show</div>
            <div className='col-2 text-center'>Default</div>
          </div>

          {/* Data Rows */}
          {selectedCalculationType &&
            selectedCalculationType.config.map((config: any, index: number) => {
              // Find the label for the current config key

              const sectionType = componentTypeSelector.find(
                (type: any) => type.id === formik.values.salary_section_id
              )
              const calculationType = sectionType.calculation_type.find(
                (cT: any) => selectedCalculationType.key === cT.key
              )
              const label =
                componentTypeSelector
                  .flatMap((type: any) => type.calculation_type)
                  .find((calcType: any) => calcType.key === config.key)?.label || config.key

              return (
                <div
                  className={`row py-2 align-items-center ${index % 2 === 0 ? 'bg-light' : ''}`}
                  key={index}
                  style={{borderBottom: '1px solid #eee'}}
                >
                  {/* Config Key as Label */}
                  <div className='col-6 text-dark'>{calculationType.config[label]}</div>

                  {/* Editable */}
                  <div className='col-2 text-center'>
                    <input
                      type='checkbox'
                      className='form-check-input custom-checkbox'
                      checked={config.is_editable}
                      onChange={() =>
                        handleCheckboxChange(config.key, 'is_editable', !config.is_editable)
                      }
                    />
                  </div>

                  {/* Show */}
                  <div className='col-2 text-center'>
                    <input
                      type='checkbox'
                      className='form-check-input custom-checkbox'
                      checked={config.is_show}
                      onChange={() => handleCheckboxChange(config.key, 'is_show', !config.is_show)}
                    />
                  </div>

                  {/* Default */}
                  <div className='col-2 text-center'>
                    <input
                      type='checkbox'
                      className='form-check-input custom-checkbox'
                      checked={config.default_value}
                      onChange={() =>
                        handleCheckboxChange(config.key, 'default_value', !config.default_value)
                      }
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default ComponentListForm
