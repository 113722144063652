import instance from './instance'

export interface ModuleAdd {
  leaves_id: string,
  module_id: Array<number>
}

export const createUpcomingHoliday = async (payload: any) => await instance.post('modules-hrm/leaves', payload)

export const getUpcomingHolidayList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`modules-hrm/upcoming-holidays?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateUpcomingHoliday = async (id: any, payload: any) => await instance.post(`modules-hrm/leaves/${id}`, payload)

export const deleteUpcomingHoliday = (id: any) =>
  instance.delete(`modules-hrm/leaves/${id}`)

export const getUpcomingHolidayById = (id: number) => instance.get(`modules-hrm/leaves/${id}`)

