import instance from './instance'

const url = 'modules-hrm/leave_template'

export const getLeavePolicyList = (
  page: number,
  pageSize: number,
  filterkey: any,
  isDropdown: number
) =>
  instance.get(
    `${url}?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&isDropdown=${isDropdown}`
  )


export const getLeavePolicyRoaster = (
  page: number,
  pageSize: number,
  filterkey: any,
  isDropdown: number
) =>
  instance.get(
    `modules-hrm/employee-leave-mapping?page=${page}&pageSize=${pageSize}&filterkey=${filterkey.search_key}&organization_location_id=${filterkey?.organization_location_id}&department_id=${filterkey?.department_id}&designation_id=${filterkey?.designation_id}&employee_id=${filterkey?.employee_id}&isDropdown=${isDropdown}`
  )

export const postLeavePolicyRoaster = (payload:any,id:any) =>instance.post(`modules-hrm/employee-leave-mapping/${id}`,payload)


export const getLeavePolicyById = (id: string) => instance.get(`${url}/${id}`)

export const createLeavePolicy = (payload: any) => instance.post(url, payload)

export const updateLeavePolicy = (id: any, payload: any) => instance.post(`${url}/${id}`, payload)

export const deleteLeavePolicy = (id: string) => instance.delete(`${url}/${id}`)
