import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {getComponentTypeList} from '../../services/ComponentTypeService'

interface ComponentTypeState {
  componentTypeDetailsList: any | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: ComponentTypeState = {
  componentTypeDetailsList: [],
  status: 'idle',
  error: null,
}

interface GetListParams {
  pageNumber?: number
  pageSize?: number
  filterkey?: any
  isDropdown?: number
}

// Thunks
export const getComponentTypeListApi = createAsyncThunk(
  'componentType/getComponentTypeList',
  async ({pageNumber, pageSize, filterkey, isDropdown = 0}: GetListParams, {rejectWithValue}) => {
    try {
      const result = await getComponentTypeList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

// Slice
const ComponentTypeSlice = createSlice({
  name: 'componentType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get ComponentType List
      .addCase(getComponentTypeListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getComponentTypeListApi.fulfilled, (state, action) => {
        state.componentTypeDetailsList = action.payload?.data?.data
        state.status = 'succeeded'
      })
      .addCase(getComponentTypeListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export default ComponentTypeSlice
