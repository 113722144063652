import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {
  createSalaryComponent,
  deleteSalaryComponent,
  getSalaryComponentById,
  getSalaryComponentList,
  getSalaryType,
  getSalaryTypeByName,
  updateSalaryComponent, // import the update API
} from '../../services/SalaryComponentService'

interface SalaryComponentState {
  typeList: any | null
  type: any | null
  salaryComponentDetailsList: any | null
  salaryComponentDetails: any | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: SalaryComponentState = {
  typeList: [],
  type: null,
  salaryComponentDetailsList: null,
  salaryComponentDetails: null,
  status: 'idle',
  error: null,
}

interface GetListParams {
  pageNumber?: number
  pageSize?: number
  filterkey?: any
  isDropdown?: number
  salarySectionId?: number
}

interface GetSalaryComponentByIdParams {
  id: string
}

interface UpdateSalaryComponentParams {
  id: string | undefined
  data: any
}

// Thunks
export const getSalaryComponentListApi = createAsyncThunk(
  'salaryComponent/getSalaryComponentList',
  async (
    {pageNumber, pageSize, filterkey, isDropdown = 0, salarySectionId}: GetListParams,
    {rejectWithValue}
  ) => {
    try {
      const result = await getSalaryComponentList(
        pageNumber,
        pageSize,
        filterkey,
        isDropdown,
        salarySectionId
      )
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const createSalaryComponentApi = createAsyncThunk(
  'salaryComponent/createSalaryComponent',
  async (salaryComponentData: any, {rejectWithValue}) => {
    try {
      const result = await createSalaryComponent(salaryComponentData)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to create Leave Policy')
    }
  }
)

export const getSalaryComponentByIdApi = createAsyncThunk(
  'salaryComponent/getSalaryComponentById',
  async ({id}: GetSalaryComponentByIdParams, {rejectWithValue}) => {
    try {
      const result = await getSalaryComponentById(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Leave Policy not found')
    }
  }
)

// Update SalaryComponent
export const updateSalaryComponentApi = createAsyncThunk(
  'salaryComponent/updateSalaryComponent',
  async ({id, data}: UpdateSalaryComponentParams, {rejectWithValue}) => {
    try {
      const result = await updateSalaryComponent(id, data)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to update Leave Policy')
    }
  }
)

export const deleteSalaryComponentApi = createAsyncThunk(
  'salaryComponent/deleteSalaryComponentApi',
  async (id: any, {rejectWithValue}) => {
    console.log('Delete Leave', id)
    try {
      const result = await deleteSalaryComponent(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to delete Leave Policy')
    }
  }
)

export const getSalaryTypeListApi = createAsyncThunk(
  'salaryComponent/getSalaryTypeListApi',
  async ({type}: any, {rejectWithValue}) => {
    try {
      const result = await getSalaryType(type)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const getSalaryTypeByNameApi = createAsyncThunk(
  'salaryComponent/getSalaryTypeByNameApi',
  async ({type, name}: any, {rejectWithValue}) => {
    try {
      const result = await getSalaryTypeByName(type, name)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

// Slice
const SalaryComponentSlice = createSlice({
  name: 'salaryComponent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get SalaryComponent List
      .addCase(getSalaryComponentListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getSalaryComponentListApi.fulfilled, (state, action) => {
        state.salaryComponentDetailsList = action.payload
        state.status = 'succeeded'
      })
      .addCase(getSalaryComponentListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Create SalaryComponent
      .addCase(createSalaryComponentApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(createSalaryComponentApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(createSalaryComponentApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Get SalaryComponent By ID
      .addCase(getSalaryComponentByIdApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getSalaryComponentByIdApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded'
        state.salaryComponentDetails = action.payload
      })
      .addCase(getSalaryComponentByIdApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Update SalaryComponent
      .addCase(updateSalaryComponentApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(updateSalaryComponentApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(updateSalaryComponentApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Delete SalaryComponent
      .addCase(deleteSalaryComponentApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(deleteSalaryComponentApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(deleteSalaryComponentApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // getSalaryTypeList
      .addCase(getSalaryTypeListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getSalaryTypeListApi.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.typeList = action.payload?.data?.data
      })
      .addCase(getSalaryTypeListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // getSalaryType
      .addCase(getSalaryTypeByNameApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getSalaryTypeByNameApi.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.type = action.payload?.data?.data[0]
      })
      .addCase(getSalaryTypeByNameApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export default SalaryComponentSlice
