import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {
  createDepartment,
  getDepartmentList,
  getDepartmentById,
  updateDepartment,
  deleteDepartment,
} from '../../services/DepartmentService'

interface GetDepartmentListParams {
  pageNumber?: number
  pageSize?: number
  filterkey?: any
  isDropdown?: number
}

interface DepartmentState {
  departmentList: any
  departmentData: any
  createDepartmentData: any
  updateDepartmentData: any
}

const initialState: DepartmentState = {
  departmentList: null,
  departmentData: null,
  createDepartmentData: null,
  updateDepartmentData: null,
}

export const createDepartmentApi = createAsyncThunk(
  'create-Department/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createDepartment(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getDepartmentApi = createAsyncThunk(
  'DepartmentList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetDepartmentListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getDepartmentList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getDepartmentByIdApi = createAsyncThunk(
  'DepartmentById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getDepartmentById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateDepartmentApi = createAsyncThunk(
  'updateDepartment/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateDepartment(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteDepartmentApi = createAsyncThunk(
  'Department/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteDepartment(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const DepartmentSlice = createSlice({
  name: 'Role',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getDepartmentApi.fulfilled, (state, action) => {
        state.departmentList = action.payload
      })
      .addCase(getDepartmentByIdApi.fulfilled, (state, action) => {
        state.departmentData = action.payload
      })
      .addCase(createDepartmentApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createDepartmentData = action?.payload
      })
      .addCase(updateDepartmentApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateDepartmentData = action?.payload
      })
  },
})
export default DepartmentSlice
