import {FC, useEffect} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import {IconPickerItem} from 'react-fa-icon-picker'
import {useThemeMode} from '../../../../partials'
import {useTheme} from '../../../../partials/layout/app-theme/ThemeContext'
import path from 'path'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  fontAwesomeIcon?: string
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  fontAwesomeIcon,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const {mode} = useThemeMode() // Get the current theme mode

  // Determine the text color class based on the theme mode
  const {theme} = useTheme()
  const textColorClass = mode === 'dark' ? 'text-light' : 'text-dark'

  console.log(pathname)
  return (
    <div className={`menu-item theme-${mode}-show`}>
      <Link
        className={clsx('menu-link d-flex align-items-center without-sub', {active: isActive})}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon me-3'>
            <KTIcon iconName={icon} className={`fs-2 text-${theme}`} />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3 me-3', fontIcon)}></i>
        )}
        {fontAwesomeIcon && (
          <span className={`menu-icon me-3 text-${theme}`}>
            <IconPickerItem icon={fontAwesomeIcon} size={16} color={theme} />
          </span>
        )}
        <span className={clsx('menu-title', textColorClass)}>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {SidebarMenuItem}
