import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {currentUser, getUserSetting} from '../../services/AuthService'

// Define the initial state for the auth slice
interface UserState {
  user: any // Adjust type according to your user model
  modules: any
  userSettings: any
}

const initialState: UserState = {
  user: null,
  modules: null,
  userSettings: null,
}

// Async thunk for fetching the current user
export const getCurrentUser = createAsyncThunk(
  'getCurrentUser/auth/api',
  async (_, {rejectWithValue}) => {
    try {
      const result = await currentUser()
      return result.data // Ensure the API returns data in the expected structure
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to fetch user')
    }
  }
)

// // Async thunk for fetching the current user
export const getUserSettingApi = createAsyncThunk(
  'getUserSetting/auth/api',
  async (_, {rejectWithValue}) => {
    try {
      const result = await getUserSetting()
      return result.data // Ensure the API returns data in the expected structure
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to fetch user')
    }
  }
)


// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.user = null
      state.modules = null
      state.userSettings = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload.data.user
      state.modules = action.payload.data.module
      // state.userSettings = action.payload.data.usersettings
    }).addCase(getUserSettingApi.fulfilled, (state, action) => {
      state.userSettings = action.payload
    })
  },
})

// Export the actions
export const {resetAuthState} = authSlice.actions

// Export the reducer to be used in the store
export default authSlice
