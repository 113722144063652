import instance from './instance'

const url = 'modules-hrm/salary-section'

export const getComponentTypeList = (
  page?: number,
  pageSize?: number,
  filterkey?: any,
  isDropdown?: number
) => {
  // Build query parameters dynamically based on the arguments
  const queryParams = new URLSearchParams()

  if (page !== undefined) {
    queryParams.append('page', page.toString())
  }
  if (pageSize !== undefined) {
    queryParams.append('pageSize', pageSize.toString())
  }
  if (filterkey) {
    queryParams.append('filterkey', filterkey)
  }
  if (isDropdown !== undefined) {
    queryParams.append('isDropdown', isDropdown.toString())
  }

  return instance.get(`${url}?${queryParams.toString()}`)
}

// export const getLeavePolicyById = (id: string) => instance.get(`${url}/${id}`)

// export const createLeavePolicy = (payload: any) => instance.post(url, payload)

// export const updateLeavePolicy = (id: any, payload: any) => instance.post(`${url}/${id}`, payload)

// export const deleteLeavePolicy = (id: string) => instance.delete(`${url}/${id}`)
