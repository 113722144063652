import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {
  getUpcomingHolidayList,
  getUpcomingHolidayById,
  deleteUpcomingHoliday,
  createUpcomingHoliday,
  updateUpcomingHoliday,
} from '../../services/UpcomingHolidayService';

interface GetUpcomingHolidayListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface UpcomingHolidayState {
  UpcomingHolidayList: any
  leaveApplicationData: any
  createUpcomingHolidayData: any
  updateUpcomingHolidayData: any
  employeeListData:any
  loading: {
    leaveLedgerList: boolean
    leaveApplicationData: boolean
    createUpcomingHoliday: boolean
    updateUpcomingHoliday: boolean
    leaveCategoryListData: boolean
    employeeListData:any
  }
}

const initialState: UpcomingHolidayState = {
  UpcomingHolidayList: null,
  leaveApplicationData: null,
  createUpcomingHolidayData: null,
  updateUpcomingHolidayData: null,
  employeeListData:null,
  loading: {
    leaveLedgerList: false,
    leaveApplicationData: false,
    createUpcomingHoliday: false,
    updateUpcomingHoliday: false,
    leaveCategoryListData: false,
    employeeListData:null,
  },
}

export const createUpcomingHolidayApi = createAsyncThunk(
  'create-workflow/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createUpcomingHoliday(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getUpcomingHolidayListApi = createAsyncThunk(
  'UpcomingHolidayList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetUpcomingHolidayListParams,
    {rejectWithValue}
  ) => {
    try {
      const result = await getUpcomingHolidayList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const getUpcomingHolidayByIdApi = createAsyncThunk(
  'UpcomingHolidayById/api/get',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await getUpcomingHolidayById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateUpcomingHolidayApi = createAsyncThunk(
  'updateUpcomingHoliday/api/put',
  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateUpcomingHoliday(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteUpcomingHolidayApi = createAsyncThunk(
  'UpcomingHoliday/api/delete',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteUpcomingHoliday(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



const UpcomingHolidaySlice = createSlice({
  name: 'UpcomingHoliday',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getUpcomingHolidayListApi
      .addCase(getUpcomingHolidayListApi.pending, (state) => {
        state.loading.leaveLedgerList = true
      })
      .addCase(getUpcomingHolidayListApi.fulfilled, (state, action) => {
        state.UpcomingHolidayList = action.payload
        state.loading.leaveLedgerList = false
      })
      .addCase(getUpcomingHolidayListApi.rejected, (state) => {
        state.loading.leaveLedgerList = false
      })

      // getUpcomingHolidayByIdApi
      .addCase(getUpcomingHolidayByIdApi.pending, (state) => {
        state.loading.leaveApplicationData = true
      })
      .addCase(getUpcomingHolidayByIdApi.fulfilled, (state, action) => {
        state.leaveApplicationData = action.payload
        state.loading.leaveApplicationData = false
      })
      .addCase(getUpcomingHolidayByIdApi.rejected, (state) => {
        state.loading.leaveApplicationData = false
      })

      // createUpcomingHolidayApi
      .addCase(createUpcomingHolidayApi.pending, (state) => {
        state.loading.createUpcomingHoliday = true
      })
      .addCase(createUpcomingHolidayApi.fulfilled, (state, action) => {
        state.createUpcomingHolidayData = action.payload
        state.loading.createUpcomingHoliday = false
      })
      .addCase(createUpcomingHolidayApi.rejected, (state) => {
        state.loading.createUpcomingHoliday = false
      })

      // updateUpcomingHolidayApi
      .addCase(updateUpcomingHolidayApi.pending, (state) => {
        state.loading.updateUpcomingHoliday = true
      })
      .addCase(updateUpcomingHolidayApi.fulfilled, (state, action) => {
        state.updateUpcomingHolidayData = action.payload
        state.loading.updateUpcomingHoliday = false
      })
      .addCase(updateUpcomingHolidayApi.rejected, (state) => {
        state.loading.updateUpcomingHoliday = false
      })

      // deleteUpcomingHolidayApi
      .addCase(deleteUpcomingHolidayApi.pending, (state) => {
        state.loading.updateUpcomingHoliday = true
      })
      .addCase(deleteUpcomingHolidayApi.fulfilled, (state, action) => {
        state.updateUpcomingHolidayData = action.payload
        state.loading.updateUpcomingHoliday = false
      })
      .addCase(deleteUpcomingHolidayApi.rejected, (state) => {
        state.loading.updateUpcomingHoliday = false
      })

  },
})

export default UpcomingHolidaySlice
