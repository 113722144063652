import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {
  getLeaveList,
  getLeaveById,
  deleteLeave,
  createLeave,
  updateLeave,
  getLeaveCategoryList,
} from '../../services/LeaveService'

interface GetLeaveListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface LeaveState {
  LeaveList: any
  leaveApplicationData: any
  createLeaveData: any
  updateLeaveData: any
  leaveCategoryListData:any
  employeeListData:any
  loading: {
    organizationList: boolean
    leaveApplicationData: boolean
    createLeave: boolean
    updateLeave: boolean
    leaveCategoryListData: boolean
    employeeListData:any
  }
}

const initialState: LeaveState = {
  LeaveList: null,
  leaveApplicationData: null,
  createLeaveData: null,
  updateLeaveData: null,
  leaveCategoryListData:null,
  employeeListData:null,
  loading: {
    organizationList: false,
    leaveApplicationData: false,
    createLeave: false,
    updateLeave: false,
    leaveCategoryListData: false,
    employeeListData:null,
  },
}

export const createLeaveApi = createAsyncThunk(
  'create-workflow/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createLeave(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getLeaveListApi = createAsyncThunk(
  'LeaveList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetLeaveListParams,
    {rejectWithValue}
  ) => {
    try {
      const result = await getLeaveList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const GetleaveCategoryListApi = createAsyncThunk(
    'LeaveTypeList/api/get',
    async (
      {pageNumber, pageSize, filterkey, isDropdown}: GetLeaveListParams,
      {rejectWithValue}
    ) => {
      try {
        const result = await getLeaveCategoryList(pageNumber, pageSize, filterkey, isDropdown)
        return result.data
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )

export const getLeaveByIdApi = createAsyncThunk(
  'LeaveById/api/get',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await getLeaveById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateLeaveApi = createAsyncThunk(
  'updateLeave/api/put',
  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateLeave(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteLeaveApi = createAsyncThunk(
  'Leave/api/delete',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteLeave(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



const LeaveSlice = createSlice({
  name: 'Leave',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getLeaveListApi
      .addCase(getLeaveListApi.pending, (state) => {
        state.loading.organizationList = true
      })
      .addCase(getLeaveListApi.fulfilled, (state, action) => {
        state.LeaveList = action.payload
        state.loading.organizationList = false
      })
      .addCase(getLeaveListApi.rejected, (state) => {
        state.loading.organizationList = false
      })

      // getLeaveByIdApi
      .addCase(getLeaveByIdApi.pending, (state) => {
        state.loading.leaveApplicationData = true
      })
      .addCase(getLeaveByIdApi.fulfilled, (state, action) => {
        state.leaveApplicationData = action.payload
        state.loading.leaveApplicationData = false
      })
      .addCase(getLeaveByIdApi.rejected, (state) => {
        state.loading.leaveApplicationData = false
      })

      // createLeaveApi
      .addCase(createLeaveApi.pending, (state) => {
        state.loading.createLeave = true
      })
      .addCase(createLeaveApi.fulfilled, (state, action) => {
        state.createLeaveData = action.payload
        state.loading.createLeave = false
      })
      .addCase(createLeaveApi.rejected, (state) => {
        state.loading.createLeave = false
      })

      // updateLeaveApi
      .addCase(updateLeaveApi.pending, (state) => {
        state.loading.updateLeave = true
      })
      .addCase(updateLeaveApi.fulfilled, (state, action) => {
        state.updateLeaveData = action.payload
        state.loading.updateLeave = false
      })
      .addCase(updateLeaveApi.rejected, (state) => {
        state.loading.updateLeave = false
      })

      // deleteLeaveApi
      .addCase(deleteLeaveApi.pending, (state) => {
        state.loading.updateLeave = true
      })
      .addCase(deleteLeaveApi.fulfilled, (state, action) => {
        state.updateLeaveData = action.payload
        state.loading.updateLeave = false
      })
      .addCase(deleteLeaveApi.rejected, (state) => {
        state.loading.updateLeave = false
      })

      .addCase(GetleaveCategoryListApi.pending, (state) => {
        state.loading.leaveCategoryListData = true
      })
      .addCase(GetleaveCategoryListApi.fulfilled, (state, action) => {
        state.leaveCategoryListData = action.payload
        state.loading.leaveCategoryListData = false
      })
      .addCase(GetleaveCategoryListApi.rejected, (state) => {
        state.loading.leaveCategoryListData = false
      })

  },
})

export default LeaveSlice
