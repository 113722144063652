import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import ComponentTable from './components/ComponentTable'
import ComponentForm from './components/ComponentForm'

const hrmBreadcrumb: PageLink = {title: 'HRM', path: '/', isSeparator: false, isActive: false}

const hrmComponentTypeBreadcrumb: PageLink = {
  title: 'Component Type',
  path: '/technical-setting/component-type',
  isSeparator: false,
  isActive: false,
}

const ComponentTypePage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
              <PageTitle breadcrumbs={[hrmBreadcrumb]}>Component type</PageTitle>
              <ComponentTable />
            </>
          }
        />
        <Route
          path='/add'
          element={
            <>
              <PageTitle breadcrumbs={[hrmBreadcrumb, hrmComponentTypeBreadcrumb]}>
                Add Component Type
              </PageTitle>
              <ComponentForm />
            </>
          }
        />
        <Route
          path='/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={[hrmBreadcrumb, hrmComponentTypeBreadcrumb]}>
                Edit Component Type
              </PageTitle>
              <ComponentForm />
            </>
          }
        />
      </Route>

      {/* Page Not Found */}
      <Route path='*' element={<Navigate to='/error/404' />} />
    </Routes>
  )
}

export default ComponentTypePage
