import instance from './instance'

export const createGroup = async (payload: any) => {
  return await instance.post('groups', payload).then((response: any) => {
  if (response) {
    return response.data
  }
}).catch((err: any) => {
  if (err.response) {
    return err.response.data
  }
}
)
}

export const getGroupList = (page: number, pageSize: number, filterkey: any) =>
  instance.get(`groups?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}`)

export const updateGroup = async (id: any, payload: any) => {
  return await instance.post(`groups/${id}`, payload).then((response: any) => {
    if (response) {
      return response.data
    }
  }).catch((err: any) => {
    if (err.response) {
      return err.response.data
    }
  }
  )
}

export const deleteGroup = (id: any) =>
  instance.delete(`groups/${id}`)

export const getGroupById = (id: number) => instance.get(`groups/${id}`)

export const getAllUsers = () => instance.get(`userList?is_dropdown=1`)
export const getAllRoles = () => instance.get(`roles`)

export const assignRolesToGroup = (id: any, payload: any) => instance.post(`assignrolestogroup/${id}`, payload)

export const manageUsersforGroup = (id: any, payload: any) => instance.post(`manageusersforgroup/${id}`, payload)