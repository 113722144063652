import React from 'react'
import { TableSearchComponent } from '../search-input/TableSearchComponent'
import { Button, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'
import { useTheme } from '../../layout/app-theme/ThemeContext'

const TableHeader = ({searchKey,handleSearchChange,addButtonText,openModal,AdditionalButtonComponent}:
    {searchKey?:string,
        handleSearchChange?:(searchQuery: string) => void,
        addButtonText:string,
    openModal?: ()=>void
    AdditionalButtonComponent?:()=>any
  }) => {

    const {theme} = useTheme()

    const renderFilterDropdown = (
      <Popover id='popover-filter'>
        <Popover.Header as='h3'>Filters</Popover.Header>
        <Popover.Body>
          {/* Add your filter form fields here */}
          <p>Filter form content goes here.</p>
          <div className='d-flex justify-content-end'>
            <Button variant='secondary' className='me-2'>
              Close
            </Button>
            <Button variant={`${theme}`}>Apply Filters</Button>
          </div>
        </Popover.Body>
      </Popover>
    )


  return (
    <div
          className={`card-header py-7 flex-wrap flex-md-nowrap w-100 justify-content-between align-items-center gap-3 shadow-sm`}
          style={{
            position: 'relative', // Needed for pseudo-element positioning
            paddingLeft: '10px', // Adjust padding to accommodate border
          }}
        >
            {typeof searchKey != "undefined" && typeof handleSearchChange != "undefined"  ?
          <TableSearchComponent searchQuery={searchKey} onSearchChange={handleSearchChange} />
           : null}
           
          <div className='d-flex ms-auto'>
           {typeof openModal!="undefined"? <OverlayTrigger placement='bottom' overlay={<Tooltip>{addButtonText}</Tooltip>}>
              <button
                className={
                  'btn btn-light border rounded-circle shadow d-flex justify-content-center align-items-center symbol symbol-40px me-5'
                }
                onClick={openModal}
                style={{width: '40px', height: '40px'}}
              >
                <i
                  className={`bi bi-plus-lg fs-2 text-${theme} pe-0`}
                  style={{transform: 'translateY(-1px)'}}
                ></i>
              </button>
            </OverlayTrigger>:null}

            {typeof AdditionalButtonComponent !="undefined" ?AdditionalButtonComponent():null}
            <OverlayTrigger
              trigger='click'
              placement='bottom'
              overlay={renderFilterDropdown}
              rootClose
            >
              <button
                className={
                  'btn btn-light border rounded-circle shadow d-flex justify-content-center align-items-center symbol symbol-40px me-5'
                }
                style={{width: '40px', height: '40px'}}
              >
                <i
                  className={`bi bi-funnel-fill fs-2 text-${theme} pe-0`}
                  style={{transform: 'translateY(-1px)'}}
                ></i>
              </button>
            </OverlayTrigger>
          </div>
          <span
            className={`border border-${theme} border-left-4`}
            style={{
              content: '',
              position: 'absolute',
              top: 12,
              left: 0,
              height: '70%',
              width: '5px', // Adjust width as needed
            }}
          />
        </div>
  )
}

export default TableHeader