import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {
  getLeaveLedgerList,
  getLeaveLedgerById,
  deleteLeaveLedger,
  createLeaveLedger,
  updateLeaveLedger,
} from '../../services/LeaveLedgerService';

interface GetLeaveLedgerLedgerListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface LeaveLedgerState {
  LeaveLedgerList: any
  leaveApplicationData: any
  createLeaveLedgerData: any
  updateLeaveLedgerData: any
  employeeListData:any
  loading: {
    leaveLedgerList: boolean
    leaveApplicationData: boolean
    createLeaveLedger: boolean
    updateLeaveLedger: boolean
    leaveCategoryListData: boolean
    employeeListData:any
  }
}

const initialState: LeaveLedgerState = {
  LeaveLedgerList: null,
  leaveApplicationData: null,
  createLeaveLedgerData: null,
  updateLeaveLedgerData: null,
  employeeListData:null,
  loading: {
    leaveLedgerList: false,
    leaveApplicationData: false,
    createLeaveLedger: false,
    updateLeaveLedger: false,
    leaveCategoryListData: false,
    employeeListData:null,
  },
}

export const createLeaveLedgerApi = createAsyncThunk(
  'create-workflow/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createLeaveLedger(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getLeaveLedgerListApi = createAsyncThunk(
  'LeaveLedgerList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetLeaveLedgerLedgerListParams,
    {rejectWithValue}
  ) => {
    try {
      const result = await getLeaveLedgerList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const getLeaveLedgerByIdApi = createAsyncThunk(
  'LeaveLedgerById/api/get',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await getLeaveLedgerById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateLeaveLedgerApi = createAsyncThunk(
  'updateLeaveLedger/api/put',
  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateLeaveLedger(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteLeaveLedgerApi = createAsyncThunk(
  'LeaveLedger/api/delete',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteLeaveLedger(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



const LeaveLedgerSlice = createSlice({
  name: 'LeaveLedger',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getLeaveLedgerListApi
      .addCase(getLeaveLedgerListApi.pending, (state) => {
        state.loading.leaveLedgerList = true
      })
      .addCase(getLeaveLedgerListApi.fulfilled, (state, action) => {
        state.LeaveLedgerList = action.payload
        state.loading.leaveLedgerList = false
      })
      .addCase(getLeaveLedgerListApi.rejected, (state) => {
        state.loading.leaveLedgerList = false
      })

      // getLeaveLedgerByIdApi
      .addCase(getLeaveLedgerByIdApi.pending, (state) => {
        state.loading.leaveApplicationData = true
      })
      .addCase(getLeaveLedgerByIdApi.fulfilled, (state, action) => {
        state.leaveApplicationData = action.payload
        state.loading.leaveApplicationData = false
      })
      .addCase(getLeaveLedgerByIdApi.rejected, (state) => {
        state.loading.leaveApplicationData = false
      })

      // createLeaveLedgerApi
      .addCase(createLeaveLedgerApi.pending, (state) => {
        state.loading.createLeaveLedger = true
      })
      .addCase(createLeaveLedgerApi.fulfilled, (state, action) => {
        state.createLeaveLedgerData = action.payload
        state.loading.createLeaveLedger = false
      })
      .addCase(createLeaveLedgerApi.rejected, (state) => {
        state.loading.createLeaveLedger = false
      })

      // updateLeaveLedgerApi
      .addCase(updateLeaveLedgerApi.pending, (state) => {
        state.loading.updateLeaveLedger = true
      })
      .addCase(updateLeaveLedgerApi.fulfilled, (state, action) => {
        state.updateLeaveLedgerData = action.payload
        state.loading.updateLeaveLedger = false
      })
      .addCase(updateLeaveLedgerApi.rejected, (state) => {
        state.loading.updateLeaveLedger = false
      })

      // deleteLeaveLedgerApi
      .addCase(deleteLeaveLedgerApi.pending, (state) => {
        state.loading.updateLeaveLedger = true
      })
      .addCase(deleteLeaveLedgerApi.fulfilled, (state, action) => {
        state.updateLeaveLedgerData = action.payload
        state.loading.updateLeaveLedger = false
      })
      .addCase(deleteLeaveLedgerApi.rejected, (state) => {
        state.loading.updateLeaveLedger = false
      })

  },
})

export default LeaveLedgerSlice
