import React, {useEffect} from 'react'
import ComponentDetailForm from './ComponentDetailForm'
import ComponentListForm from './ComponentListForm'
import {useDispatch, useSelector} from 'react-redux'
import {AppDispatch, RootState} from '../../../redux/store'
import {getComponentTypeListApi} from '../../../redux/slices/ComponentTypeSlice'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {
  createSalaryComponentTypeApi,
  getSalaryComponentTypeByIdApi,
  updateSalaryComponentTypeApi,
} from '../../../redux/slices/SalaryComponentTypeSlice'
import {toast} from 'react-toastify'
import {useNavigate, useParams} from 'react-router-dom'

const ComponentForm = () => {
  const dispatch = useDispatch<AppDispatch>()
  const componentTypeSelector = useSelector(
    (state: RootState) => state.componentType.componentTypeDetailsList
  )
  const navigate = useNavigate()
  const {id} = useParams<{id: string}>()

  const fetchComponentType = () => {
    dispatch(getComponentTypeListApi({isDropdown: 1}))
  }

  const fetchComponentDetails = async () => {
    if (id) {
      const response = await dispatch(getSalaryComponentTypeByIdApi({id})).unwrap()
      console.log(response)
      formik.setValues({
        name: response?.data?.data?.name || '',
        salary_section_id: response?.data?.data?.salary_section_id || '',
        configuration_json: response?.data?.data?.configuration_json || [],
      })
    }
  }

  // Define validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    salary_section_id: Yup.string().required('Salary Section ID is required'),
    configuration_json: Yup.array(),
  })

  // Initialize Formik state
  const formik = useFormik({
    initialValues: {
      name: '',
      salary_section_id: '',
      configuration_json: [],
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (id) {
        // Update existing component
        dispatch(updateSalaryComponentTypeApi({id, data: values})).then((res: any) => {
          toast.success(res?.payload?.reason || 'Component updated successfully')
          navigate(`/hrm/salary-component`)
        })
      } else {
        // Create new component
        dispatch(createSalaryComponentTypeApi(values)).then((res: any) => {
          toast.success(res?.payload?.reason || 'Component created successfully')
          navigate(`/hrm/salary-component`)
        })
      }
    },
  })

  useEffect(() => {
    fetchComponentType()
    if (id) {
      fetchComponentDetails()
    }
  }, [dispatch, id])

  return (
    <div>
      <ComponentDetailForm formik={formik} componentTypeSelector={componentTypeSelector} id={id} />

      {formik.values.configuration_json.length >= 1 && (
        <ComponentListForm formik={formik} componentTypeSelector={componentTypeSelector} />
      )}
    </div>
  )
}

export default ComponentForm
