import instance from './instance'

export const createOrganizationLocation = async (payload: any) => {
  return await instance
    .post('organizationlocations', payload)
    .then((response: any) => {
      if (response) {
        return response.data
      }
    })
    .catch((err: any) => {
      if (err.response) {
        return err.response.data
      }
    })
}

export const getOrganizationLocationList = (
  page?: number,
  pageSize?: number,
  filterkey?: any,
  isDropdown?: any
) => {
  // Build query parameters dynamically based on the arguments
  const queryParams = new URLSearchParams()

  if (page !== undefined) {
    queryParams.append('page', page.toString())
  }
  if (pageSize !== undefined) {
    queryParams.append('pageSize', pageSize.toString())
  }
  if (filterkey) {
    queryParams.append('filterkey', filterkey)
  }
  if (isDropdown !== undefined) {
    queryParams.append('is_dropdown', isDropdown.toString())
  }

  return instance.get(`organizationlocations?${queryParams.toString()}`)
}

export const updateOrganizationLocation = async (id: any, payload: any) => {
  return await instance
    .post(`organizationlocations/${id}`, payload)
    .then((response: any) => {
      if (response) {
        return response.data
      }
    })
    .catch((err: any) => {
      if (err.response) {
        return err.response.data
      }
    })
}

export const deleteOrganizationLocation = (id: any) =>
  instance.delete(`organizationlocations/${id}`)

export const getOrganizationLocationById = (id: number) =>
  instance.get(`organizationlocations/${id}`)

export const getOrganizationLocationByOrganization = (id: number) =>
  instance.get(`organizationwiselocations/${id}`)

export const createLocationModule = async (payload: any) =>
  await instance.post('orglocationmodules', payload)

export const updateLocationModule = async (id: any, payload: any) =>
  await instance.post(`orglocationmodules/${id}`, payload)

export const getLocationModuleList = async (id: any) => await instance.get(`orglocationby/${id}`)

export const deleteLocationModule = async (id: any) =>
  await instance.delete(`orglocationmodules/${id}`)
