import instance from './instance'

const url = 'modules-hrm/salary-components'

export const getSalaryComponentList = (
  page?: number,
  pageSize?: number,
  filterkey?: any,
  isDropdown?: number,
  salarySectionId?: number
) => {
  const queryParams = new URLSearchParams()

  if (page) queryParams.append('page', page.toString())
  if (pageSize) queryParams.append('pageSize', pageSize.toString())
  if (filterkey) queryParams.append('filterkey', filterkey)

  if (isDropdown) queryParams.append('isDropdown', isDropdown.toString())
  if (salarySectionId) queryParams.append('salary_section_id', salarySectionId.toString())

  return instance.get(`${url}?${queryParams.toString()}`)
}

export const getSalaryComponentById = (id: string) => instance.get(`${url}/${id}`)

export const createSalaryComponent = (payload: any) => instance.post(url, payload)

export const updateSalaryComponent = (id: any, payload: any) =>
  instance.post(`${url}/${id}`, payload)

export const deleteSalaryComponent = (id: string) => instance.delete(`${url}/${id}`)

// Get Type
export const getSalaryType = (type: string) => instance.get(`modules-hrm/${type}`)

export const getSalaryTypeByName = (type: string, name: string) =>
  instance.get(`modules-hrm/${type}?name=${name}`)
