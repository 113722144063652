import instance from './instance'

const url = 'modules-hrm/holiday-templates'

export const getHolidayPolicyList = (
  page: number,
  pageSize: number,
  filterkey: any,
  isDropdown: number
) =>
  instance.get(
    `${url}?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&isDropdown=${isDropdown}`
  )


export const getHolidayPolicyRoaster = (
    page: number,
    pageSize: number,
    filterkey: any,
    isDropdown: number
  ) =>
    instance.get(
      `modules-hrm/employee-holiday-mapping?page=${page}&pageSize=${pageSize}&&filterkey=${filterkey.search_key}&organization_location_id=${filterkey.organization_location_id}&department_id=${filterkey?.department_id}&designation_id=${filterkey?.designation_id}&employee_id=${filterkey?.employee_id}&isDropdown=${isDropdown}`
    )
  
export const postHolidayPolicyRoaster = (payload:any,id:any) =>instance.post(`modules-hrm/employee-holiday-mapping/${id}`,payload)
    

export const getHolidayPolicyById = (id: string) => instance.get(`${url}/${id}`)

export const createHolidayPolicy = (payload: any) => instance.post(url, payload)

export const updateHolidayPolicy = (id: any, payload: any) => instance.post(`${url}/${id}`, payload)

export const deleteHolidayPolicy = (id: number) => instance.delete(`${url}/${id}`)
