import React, {useEffect, useState} from 'react'
import Select from 'react-select'

const customStyles = {
  menu: (base: any) => ({
    ...base,
    borderRadius: '6px',
    marginTop: '0px',
    fontSize: '12px',
  }),
  control: (baseStyles: any, state: any) => ({
    ...baseStyles,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'transparent',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#a1a5b7',
    fontSize: '14px',
  }),
  dropdownIndicator: (base: any, state: {selectProps: {menuIsOpen: any}}) => ({
    ...base,
    transition: 'all .4s ease',
    transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
  }),
}

interface ComponentDetailFormProps {
  id: any
  formik: any
  componentTypeSelector: any
}

const ComponentDetailForm: React.FC<ComponentDetailFormProps> = ({
  id,
  formik,
  componentTypeSelector,
}) => {
  const [calculationTypeOptions, setCalculationTypeOptions] = useState<any[]>([])

  const sectionOptions = componentTypeSelector.map((cT: any) => ({
    label: cT.name,
    value: cT.id,
  }))

  useEffect(() => {
    if (!formik.values.salary_section_id || componentTypeSelector.length === 0) {
      setCalculationTypeOptions([]) // Clear options if no salary_section_id or selector is empty
      return
    }

    const selectedSection = componentTypeSelector.find(
      (section: any) => String(section.id) === String(formik.values.salary_section_id)
    )

    if (selectedSection?.calculation_type) {
      const mappedOptions = selectedSection.calculation_type.map((calc: any) => ({
        value: calc.key,
        label: calc.label,
        config: calc.config,
      }))
      setCalculationTypeOptions(mappedOptions)
    } else {
      setCalculationTypeOptions([]) // Clear options if calculation_type is missing
    }
  }, [formik.values.salary_section_id, componentTypeSelector])

  const handleCalculationTypeChange = (selectedOptions: any) => {
    const updatedCalculationTypes = selectedOptions.map((selected: any) => {
      const existingType = formik.values.configuration_json.find(
        (calc: any) => calc.key === selected.value
      )

      const updatedConfig = Object.keys(selected.config || {}).map((key: string) => {
        const existingConfig = existingType?.config.find((cfg: any) => cfg.key === key)
        return {
          key,
          is_editable: existingConfig?.is_editable ?? false,
          is_show: existingConfig?.is_show ?? true,
          default_value: existingConfig?.default_value ?? false,
        }
      })

      return {
        key: selected.value,
        config: updatedConfig,
      }
    })

    formik.setFieldValue('configuration_json', updatedCalculationTypes)
  }

  return (
    <div className='card py-5 px-6'>
      <form onSubmit={formik.handleSubmit} className='form'>
        <div className='d-flex w-100 justify-content-between align-items-center'>
          <h2>Component Type Form</h2>
          {!id ? (
            <div>
              <button type='submit' className='btn btn-primary'>
                Submit
              </button>
            </div>
          ) : null}
        </div>

        <div className='row g-3'>
          {/* Name Field */}
          <div className='col-12 col-md-4'>
            <label className='form-label fs-6 fw-bold text-dark'>Name</label>
            <input
              type='text'
              name='name'
              className='form-control form-control-lg form-control-solid'
              placeholder='Enter name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='text-danger mt-1'>{formik.errors.name}</div>
            )}
          </div>

          {/* Salary Section Field */}
          <div className='col-12 col-md-4'>
            <label className='form-label fs-6 fw-bold text-dark'>Salary Section</label>
            <Select
              name='salary_section_id'
              options={sectionOptions}
              onChange={(selectedOption: any) =>
                formik.setFieldValue(
                  'salary_section_id',
                  selectedOption ? selectedOption.value : null
                )
              }
              onBlur={() => formik.setFieldTouched('salary_section_id', true)}
              styles={customStyles}
              placeholder='Select salary section'
              value={sectionOptions.find(
                (option: any) => String(option.value) === String(formik.values.salary_section_id)
              )}
              className='form-control form-control-sm form-control-solid p-1'
              isClearable
            />
            {formik.touched.salary_section_id && formik.errors.salary_section_id && (
              <div className='text-danger mt-1'>{formik.errors.salary_section_id}</div>
            )}
          </div>
        </div>

        <div className='row g-3 mt-3'>
          {/* Calculation Type Field */}
          <div className='col-12 col-md-8'>
            <label className='form-label fs-6 fw-bold text-dark'>Calculation Type</label>
            <Select
              name='configuration_json'
              options={calculationTypeOptions}
              onChange={handleCalculationTypeChange}
              styles={customStyles}
              placeholder='Select calculation type'
              value={calculationTypeOptions.filter((option) =>
                formik.values.configuration_json.some((calc: any) => calc.key === option.value)
              )}
              className='form-control form-control-sm form-control-solid p-1'
              isMulti
              isClearable
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ComponentDetailForm
