import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import RoasterPage from '../modules/roaster/RoasterPage'
import ComponentTypePage from '../modules/ComponentType/ComponentTypePage'
import LeaveApplication from '../modules/hrm/components/Leave/LeaveApplication'
import LeaveLedger from '../modules/hrm/components/LeaveLedger/LeaveLedgerPage'
import UpcomingHoliday from '../modules/hrm/components/UpcomingHoliday/UpcomingholidayPage'
import DailyReport from '../modules/hrm/components/DailyReport/DailyReportPage'

const PrivateRoutes: FC = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UserPage = lazy(() => import('../modules/user-management/UserPage'))
  const SettingPage = lazy(() => import('../modules/setting/SettingPage'))
  const Ask = lazy(() => import('../modules/AskAi/Ask'))
  const HrmPage = lazy(() => import('../modules/hrm/HrmPage'))
  const EmployeePage = lazy(() => import('../modules/employee/EmployeePage'))
  const DepartmentPage = lazy(() => import('../modules/hrm/components/Department/DepartmentPage'))
  const DesignationPage = lazy(
    () => import('../modules/hrm/components/ Designation/ DesignationPage')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after successful login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard/hrm-dashboard' />} />

        {/* Pages */}
        <Route path='/dashboard/hrm-dashboard' element={<DashboardWrapper />} />

        <Route
          path='profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='settings/*'
          element={
            <SuspensedView>
              <SettingPage />
            </SuspensedView>
          }
        />

        <Route
          path='hrm/employee/*'
          element={
            <SuspensedView>
              <EmployeePage />
            </SuspensedView>
          }
        />

        <Route
          path='hrm/department/*'
          element={
            <SuspensedView>
              <DepartmentPage />
            </SuspensedView>
          }
        />

        <Route
          path='hrm/designation/*'
          element={
            <SuspensedView>
              <DesignationPage />
            </SuspensedView>
          }
        />

        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />

        <Route
          path='/ask-ai'
          element={
            <SuspensedView>
              <Ask />
            </SuspensedView>
          }
        />

        <Route
          path='hrm/*'
          element={
            <SuspensedView>
              <HrmPage />
            </SuspensedView>
          }
        />


        <Route
          path='technical-setting/component-type/*'
          element={
            <SuspensedView>
              <ComponentTypePage />
            </SuspensedView>
          }
        />

        {/* <Route
          path='/hrm/upcoming-holiday/*'
          element={
            <>
              <UpcomingHoliday />
            </>
          }
        /> */}


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')

  TopBarProgress.config({
    barColors: { '0': baseColor },
    barThickness: 1,
    shadowBlur: 5,
  })

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
