import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getRoleList} from '../../services/RoleService'
import {getRoleById} from '../../services/RoleService'
import {deleteRole} from '../../services/RoleService'
import {createRole} from '../../services/RoleService'
import {updateRole} from '../../services/RoleService'
import {getAllGroups} from '../../services/RoleService'
import {getPermissionList} from '../../services/RoleService'
import {manageGroupsForRole} from '../../services/RoleService'
import {assignPermissionsToRole} from '../../services/RoleService'
import {getAllRoleList} from '../../services/RoleService'

interface GetRoleListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
}

interface RoleState {
  roleList: any
  roleData: any
  createRoleData: any
  updateRoleData: any
  AllGroupsData: any
  PermissionListData: any
  allRoleList: any
  manageGroupsForRoleData: any
}

const initialState: RoleState = {
  roleList: null,
  roleData: null,
  createRoleData: null,
  updateRoleData: null,
  AllGroupsData: null,
  PermissionListData: null,
  allRoleList: null,
  manageGroupsForRoleData: null,
}

export const createRoleApi = createAsyncThunk(
  'create-workflow/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createRole(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getRoleListApi = createAsyncThunk(
  'RoleList/api/get',
  async (
    {pageNumber, pageSize, filterkey}: GetRoleListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getRoleList(pageNumber, pageSize, filterkey)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getRoleByIdApi = createAsyncThunk(
  'RoleById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getRoleById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateRoleApi = createAsyncThunk(
  'updateRole/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateRole(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteRoleApi = createAsyncThunk(
  'Role/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteRole(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const getAllGroupsApi = createAsyncThunk(
  'group/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getAllGroups()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getPermissionListApi = createAsyncThunk(
  'PermissionList/api/get',
  async ({is_dropdown}:{is_dropdown:any}, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getPermissionList({is_dropdown})
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const manageGroupsForRoleApi = createAsyncThunk(
  'manageGroupsForRole/api/post',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      console.log(id)
      const result = await manageGroupsForRole(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)
export const assignPermissionsToRoleApi = createAsyncThunk(
  'assignPermissionsToRole/api/post',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      console.log(id)
      const result = await assignPermissionsToRole(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getAllRoleListApi = createAsyncThunk(
  'AllRoleList/api/get',
  async (_, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getAllRoleList()
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const RoleSlice = createSlice({
  name: 'Role',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getRoleListApi.fulfilled, (state, action) => {
        state.roleList = action.payload
      })
      .addCase(getRoleByIdApi.fulfilled, (state, action) => {
        state.roleData = action.payload
      })
      .addCase(createRoleApi.fulfilled, (state, action) => {
        state.createRoleData = action?.payload
      })
      .addCase(updateRoleApi.fulfilled, (state, action) => {
        state.updateRoleData = action?.payload
      })
      .addCase(getAllGroupsApi.fulfilled, (state, action) => {
        state.AllGroupsData = action.payload
      })
      .addCase(getPermissionListApi.fulfilled, (state, action) => {
        state.PermissionListData = action.payload
      })
      .addCase(getAllRoleListApi.fulfilled, (state, action) => {
        state.allRoleList = action.payload
      })
      .addCase(manageGroupsForRoleApi.fulfilled, (state, action) => {
        state.manageGroupsForRoleData = action.payload
      })
  },
})
export default RoleSlice
