import React, {useState, useMemo, useCallback, useEffect} from 'react'
import {TablesWidget} from '../../../../_metronic/partials/widgets'
import {ConfirmationModal} from '../../../../_metronic/partials/widgets/modals/ConfirmationModal'
import TableEmployeeHeader from '../../../../_metronic/partials/widgets/tables/TableEmployeeHeader'
import {KTIcon} from '../../../../_metronic/helpers'
import TableHeader from '../../../../_metronic/partials/widgets/tables/TableHeader'
import {useNavigate} from 'react-router-dom'
import {AppDispatch, RootState} from '../../../redux/store'
import {useDispatch, useSelector} from 'react-redux'
import {getSalaryComponentListApi} from '../../../redux/slices/SalaryComponentSlice'
import {
  deleteSalaryComponentTypeApi,
  getSalaryComponentTypeListApi,
} from '../../../redux/slices/SalaryComponentTypeSlice'
import {toast} from 'react-toastify'
import {getComponentTypeListApi} from '../../../redux/slices/ComponentTypeSlice'

const ComponentTable = () => {
  const [loading, setLoading] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('')
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const navigate = useNavigate()

  const dispatch = useDispatch<AppDispatch>()
  const salaryComponentTypeData = useSelector(
    (state: RootState) => state.salaryComponentType.salaryComponentTypeDetailsList?.data?.data || []
  )

  const salaryComponentTypeTotal = useSelector(
    (state: RootState) => state.salaryComponentType.salaryComponentTypeDetailsList?.data?.total
  )

  const componentTypeSelector = useSelector(
    (state: RootState) => state.componentType.componentTypeDetailsList || []
  )

  const sectionOptions = componentTypeSelector.map((cT: any) => ({
    label: cT.name,
    value: cT.id,
  }))

  const fetchComponentType = () => {
    dispatch(getComponentTypeListApi({isDropdown: 1}))
  }

  useEffect(() => {
    fetchComponentType()
  }, [dispatch])

  const fetchCaseList = useCallback(() => {
    setLoading(true)
    dispatch(
      getSalaryComponentTypeListApi({
        pageNumber: currentPage,
        pageSize: rowsPerPage,
        filterkey: debouncedSearchQuery,
      })
    )
      .then((value) => {
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }, [dispatch, currentPage, rowsPerPage, debouncedSearchQuery])

  useEffect(() => {
    fetchCaseList()
  }, [
    fetchCaseList,
    // createCaseData, updateCaseData
  ])

  const columnData = useMemo(
    () => [
      {name: 'Name', selector: (row: any) => row.name, sortable: true},
      {
        name: 'Type',
        cell: (row: any) => {
          const matchedOption = sectionOptions.find((sO: any) => sO.value === row.salary_section_id)
          return matchedOption ? matchedOption.label : 'N/A' // Fallback to 'N/A' if no match is found
        },
        sortable: true,
      },
      {
        name: 'Created at',
        selector: (row: any) => {
          const date = new Date(row.created_at)
          return !isNaN(date.getTime())
            ? date.toLocaleDateString('en-GB') // Formats as DD/MM/YYYY for 'en-GB'
            : 'N/A' // Fallback if the date is invalid
        },
        sortable: true,
      },

      {
        name: 'Actions',
        cell: (row: any) => (
          <div className='d-flex'>
            <div
              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 rounded-circle d-flex align-items-center justify-content-center`}
              onClick={() => {
                navigate('/technical-setting/component-type/edit/' + row.id)
              }}
              style={{width: '30px', height: '30px'}}
            >
              <KTIcon iconName='eye' className='fs-4' />
            </div>
            <div
              className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 rounded-circle d-flex align-items-center justify-content-center`}
              onClick={() => {
                setSelectedId(row?.id)
                setIsDeleteModalOpen(true)
              }}
              style={{width: '30px', height: '30px'}}
            >
              <KTIcon iconName='trash' className='fs-4' />
            </div>
          </div>
        ),
        sortable: false,
      },
    ],
    [sectionOptions] // Include sectionOptions as a dependency
  )

  const handleDelete = useCallback(() => {
    if (selectedId) {
      dispatch(deleteSalaryComponentTypeApi(selectedId)).then((response: any) => {
        if (response.payload.success) {
          toast.success(response.payload.reason)
          fetchCaseList()
        } else {
          toast.error(response.payload.reason)
        }
      })
      setIsDeleteModalOpen(false)
      setSelectedId(null)
    }
  }, [dispatch, fetchCaseList, selectedId])

  const handleSearchChange = (query: string) => setSearchKey(query)

  const handlePageChange = (page: number) => setCurrentPage(page)

  const handlePerRowsChange = (newPerPage: number) => setRowsPerPage(newPerPage)

  const openHandler = () => {
    navigate('/technical-setting/component-type/add')
  }

  return (
    <div className='card'>
      <TableHeader
        searchKey={searchKey}
        handleSearchChange={handleSearchChange}
        addButtonText={'Add Salary Component Type'}
        openModal={openHandler} // Open the new holiday page
      />
      <div className='card-body p-0 shadow-sm'>
        <TablesWidget
          data={salaryComponentTypeData}
          columns={columnData}
          isLoading={loading}
          handlePageChange={handlePageChange}
          handlePerRowsChange={handlePerRowsChange}
          totalRows={salaryComponentTypeTotal}
          showPagination
        />

        <ConfirmationModal
          icon='/media/icons/duotune/general/gen027.svg'
          title='Are you sure?'
          description='Are you sure you want to delete this component?'
          onSave={handleDelete}
          open={isDeleteModalOpen}
          handleClose={() => setIsDeleteModalOpen(false)}
        />
      </div>
    </div>
  )
}

export default ComponentTable
