import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit'
import {
  createSalaryTemplateBuilder,
  deleteSalaryTemplateBuilder,
  getSalaryTemplateBuilderById,
  getSalaryTemplateBuilderList,
  updateSalaryTemplateBuilder, // import the update API
} from '../../services/SalaryTemplateBuilderService'

interface SalaryTemplateBuilderState {
  salaryTemplateBuilderDetailsList: any | null
  salaryTemplateBuilderDetails: any | null
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

const initialState: SalaryTemplateBuilderState = {
  salaryTemplateBuilderDetailsList: null,
  salaryTemplateBuilderDetails: null,
  status: 'idle',
  error: null,
}

interface GetListParams {
  pageNumber?: number
  pageSize?: number
  filterkey?: any
  isDropdown?: number
}

interface GetSalaryTemplateBuilderByIdParams {
  id: string
}

interface UpdateSalaryTemplateBuilderParams {
  id: string | undefined
  data: any
}

// Thunks
export const getSalaryTemplateBuilderListApi = createAsyncThunk(
  'salaryTemplateBuilder/getSalaryTemplateBuilderList',
  async ({pageNumber, pageSize, filterkey, isDropdown = 0}: GetListParams, {rejectWithValue}) => {
    try {
      const result = await getSalaryTemplateBuilderList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred')
    }
  }
)

export const createSalaryTemplateBuilderApi = createAsyncThunk(
  'salaryTemplateBuilder/createSalaryTemplateBuilder',
  async (salaryTemplateBuilderData: any, {rejectWithValue}) => {
    try {
      const result = await createSalaryTemplateBuilder(salaryTemplateBuilderData)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to create Leave Policy')
    }
  }
)

export const getSalaryTemplateBuilderByIdApi = createAsyncThunk(
  'salaryTemplateBuilder/getSalaryTemplateBuilderById',
  async ({id}: GetSalaryTemplateBuilderByIdParams, {rejectWithValue}) => {
    try {
      const result = await getSalaryTemplateBuilderById(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Leave Policy not found')
    }
  }
)

// Update SalaryTemplateBuilder
export const updateSalaryTemplateBuilderApi = createAsyncThunk(
  'salaryTemplateBuilder/updateSalaryTemplateBuilder',
  async ({id, data}: UpdateSalaryTemplateBuilderParams, {rejectWithValue}) => {
    try {
      const result = await updateSalaryTemplateBuilder(id, data)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to update Leave Policy')
    }
  }
)

export const deleteSalaryTemplateBuilderApi = createAsyncThunk(
  'salaryTemplateBuilder/deleteSalaryTemplateBuilderApi',
  async (id: any, {rejectWithValue}) => {
    console.log('Delete Leave', id)
    try {
      const result = await deleteSalaryTemplateBuilder(id)
      return result.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Failed to delete Leave Policy')
    }
  }
)

// Slice
const SalaryTemplateBuilderSlice = createSlice({
  name: 'salaryTemplateBuilder',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Get SalaryTemplateBuilder List
      .addCase(getSalaryTemplateBuilderListApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getSalaryTemplateBuilderListApi.fulfilled, (state, action) => {
        state.salaryTemplateBuilderDetailsList = action.payload
        state.status = 'succeeded'
      })
      .addCase(getSalaryTemplateBuilderListApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Create SalaryTemplateBuilder
      .addCase(createSalaryTemplateBuilderApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(createSalaryTemplateBuilderApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(createSalaryTemplateBuilderApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Get SalaryTemplateBuilder By ID
      .addCase(getSalaryTemplateBuilderByIdApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(getSalaryTemplateBuilderByIdApi.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = 'succeeded'
        state.salaryTemplateBuilderDetails = action.payload
      })
      .addCase(getSalaryTemplateBuilderByIdApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Update SalaryTemplateBuilder
      .addCase(updateSalaryTemplateBuilderApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(updateSalaryTemplateBuilderApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(updateSalaryTemplateBuilderApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })

      // Delete SalaryTemplateBuilder
      .addCase(deleteSalaryTemplateBuilderApi.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(deleteSalaryTemplateBuilderApi.fulfilled, (state) => {
        state.status = 'succeeded'
      })
      .addCase(deleteSalaryTemplateBuilderApi.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.payload as string
      })
  },
})

export default SalaryTemplateBuilderSlice
