import instance from './instance'

export const createEmployeeList = (payload: any) => instance.post('modules-hrm/employee', payload)

export const getEmployeeList = (
  page?: number,
  pageSize?: number,
  filterkey?: any,
  filters?: {
    location?: {value: string}
    employeeType?: {value: string}
    department?: {value: string}
    designation?: {value: string}
    gender?: {value: string}
  },
  isDropdown?: number
) => {
  // Build query parameters dynamically based on available filters
  const queryParams = new URLSearchParams()

  if (page !== undefined) {
    queryParams.append('page', page.toString())
  }
  if (pageSize !== undefined) {
    queryParams.append('pageSize', pageSize.toString())
  }
  if (filterkey) {
    queryParams.append('filterkey', filterkey)
  }
  if (filters?.location) {
    queryParams.append('organization_location_id', filters.location.value)
  }
  if (filters?.employeeType) {
    queryParams.append('employee_type_id', filters.employeeType.value)
  }
  if (filters?.department) {
    queryParams.append('department_id', filters.department.value)
  }
  if (filters?.designation) {
    queryParams.append('designation_id', filters.designation.value)
  }
  if (filters?.gender) {
    queryParams.append('gender_id', filters.gender.value)
  }
  if (isDropdown !== undefined) {
    queryParams.append('is_dropdown', isDropdown.toString())
  }

  return instance.get(`modules-hrm/employees?${queryParams.toString()}`)
}

export const updateEmployee = (id: any, payload: any) =>
  instance.post(`modules-hrm/employee/${id}`, payload)

export const deleteEmployee = (id: any) => instance.delete(`modules-hrm/employee/${id}`)

export const getEmployeeById = (id: number) => instance.get(`modules-hrm/employee/${id}`)
