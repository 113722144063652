import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {
  getSalarySlipList,
  getSalarySlipById,
  deleteSalarySlip,
  createSalarySlip,
  updateSalarySlip,
} from '../../services/SalarySlipService';

interface GetSalarySlipListParams {
  pageNumber: number
  pageSize: number
  filterkey: any
  isDropdown: number
}

interface SalarySlipState {
  salarySlipList: any
  salarySlipData: any
  createSalarySlipData: any
  updateSalarySlipData: any
  loading: {
    dailyReportList: boolean
    salarySlipData: boolean
    createSalarySlip: boolean
    updateSalarySlip: boolean
  }
}

const initialState: SalarySlipState = {
  salarySlipList: null,
  salarySlipData: null,
  createSalarySlipData: null,
  updateSalarySlipData: null,
  loading: {
    dailyReportList: false,
    salarySlipData: false,
    createSalarySlip: false,
    updateSalarySlip: false,
    },
}

export const createSalarySlipApi = createAsyncThunk(
  'create-workflow/api/post',
  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createSalarySlip(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)


export const getSalarySlipListApi = createAsyncThunk(
    'salarySlipList/api/get',
    async (payload:any,
      {rejectWithValue}
    ) => {
      try {
        const result = await getSalarySlipList(payload)
        return result.data
      } catch (error) {
        return rejectWithValue({error})
      }
    }
  )

// export const getSalarySlipListApi = createAsyncThunk(
//   'salarySlipList/api/get',
//   async (
//     {pageNumber, pageSize, filterkey, isDropdown}: GetSalarySlipListParams,
//     {rejectWithValue}
//   ) => {
//     try {
//       const result = await getSalarySlipList(pageNumber, pageSize, filterkey, isDropdown)
//       return result.data
//     } catch (error) {
//       return rejectWithValue({error})
//     }
//   }
// )


export const getSalarySlipByIdApi = createAsyncThunk(
  'SalarySlipById/api/get',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await getSalarySlipById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateSalarySlipApi = createAsyncThunk(
  'updateSalarySlip/api/put',
  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateSalarySlip(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteSalarySlipApi = createAsyncThunk(
  'SalarySlip/api/delete',
  async (id: any, {rejectWithValue}) => {
    try {
      const result = await deleteSalarySlip(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)



const SalarySlipSlice = createSlice({
  name: 'SalarySlip',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getSalarySlipListApi
      .addCase(getSalarySlipListApi.pending, (state) => {
        state.loading.dailyReportList = true
      })
      .addCase(getSalarySlipListApi.fulfilled, (state, action) => {
        state.salarySlipList = action.payload
        state.loading.dailyReportList = false
      })
      .addCase(getSalarySlipListApi.rejected, (state) => {
        state.loading.dailyReportList = false
      })

      // getSalarySlipByIdApi
      .addCase(getSalarySlipByIdApi.pending, (state) => {
        state.loading.salarySlipData = true
      })
      .addCase(getSalarySlipByIdApi.fulfilled, (state, action) => {
        state.salarySlipData = action.payload
        state.loading.salarySlipData = false
      })
      .addCase(getSalarySlipByIdApi.rejected, (state) => {
        state.loading.salarySlipData = false
      })

      // createSalarySlipApi
      .addCase(createSalarySlipApi.pending, (state) => {
        state.loading.createSalarySlip = true
      })
      .addCase(createSalarySlipApi.fulfilled, (state, action) => {
        state.createSalarySlipData = action.payload
        state.loading.createSalarySlip = false
      })
      .addCase(createSalarySlipApi.rejected, (state) => {
        state.loading.createSalarySlip = false
      })

      // updateSalarySlipApi
      .addCase(updateSalarySlipApi.pending, (state) => {
        state.loading.updateSalarySlip = true
      })
      .addCase(updateSalarySlipApi.fulfilled, (state, action) => {
        state.updateSalarySlipData = action.payload
        state.loading.updateSalarySlip = false
      })
      .addCase(updateSalarySlipApi.rejected, (state) => {
        state.loading.updateSalarySlip = false
      })

      // deleteSalarySlipApi
      .addCase(deleteSalarySlipApi.pending, (state) => {
        state.loading.updateSalarySlip = true
      })
      .addCase(deleteSalarySlipApi.fulfilled, (state, action) => {
        state.updateSalarySlipData = action.payload
        state.loading.updateSalarySlip = false
      })
      .addCase(deleteSalarySlipApi.rejected, (state) => {
        state.loading.updateSalarySlip = false
      })

  },
})

export default SalarySlipSlice
