import instance from './instance'

export interface ModuleAdd {
  leaves_id: string,
  module_id: Array<number>
}

export const createLeaveLedger = async (payload: any) => await instance.post('modules-hrm/leaves', payload)

export const getLeaveLedgerList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
  instance.get(`modules-hrm/leave-ledger?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const updateLeaveLedger = async (id: any, payload: any) => await instance.post(`modules-hrm/leaves/${id}`, payload)

export const deleteLeaveLedger = (id: any) =>
  instance.delete(`modules-hrm/leaves/${id}`)

export const getLeaveLedgerById = (id: number) => instance.get(`modules-hrm/leaves/${id}`)

