import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getEmployeeTypeList} from '../../services/EmployeeTypeService'

interface EmployeeTypeState {
  EmployeeTypeList: any
}

const initialState: EmployeeTypeState = {
  EmployeeTypeList: null,
}

export const getEmployeeTypeListApi = createAsyncThunk('EmployeeTypeList/api/get', async () => {
  try {
    const result = await getEmployeeTypeList()
    return result.data
  } catch (error) {
    return {error}
  }
})

// createOrganizationModule

const EmployeeTypeSlice = createSlice({
  name: 'EmployeeType',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getEmployeeTypeListApi.fulfilled, (state, action) => {
      state.EmployeeTypeList = action.payload
    })
  },
})

export default EmployeeTypeSlice
