import instance from './instance'

export interface ModuleAdd {
  leaves_id: string,
  module_id: Array<number>
}

export const createSalarySlip = async (payload: any) => await instance.post('modules-hrm/leaves', payload)

// export const getSalarySlipList = (page: number, pageSize: number, filterkey: any,isDropdown:any) =>
//   instance.get(`modules-hrm/daily-report?page=${page}&pageSize=${pageSize}&filterkey=${filterkey}&is_dropdown=${isDropdown}`)

export const getSalarySlipList = (payload:any) =>
    instance.post(`modules-hrm/get-employee-salary`,payload)

export const updateSalarySlip = async (id: any, payload: any) => await instance.post(`modules-hrm/leaves/${id}`, payload)

export const deleteSalarySlip = (id: any) =>
  instance.delete(`modules-hrm/leaves/${id}`)

export const getSalarySlipById = (id: number) => instance.get(`modules-hrm/leaves/${id}`)

