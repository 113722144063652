/* eslint-disable react/jsx-no-target-blank */
import { Link, useNavigate } from 'react-router-dom'
import { RiBardLine } from "react-icons/ri";
import { KTIcon, toAbsoluteUrl } from '../../../helpers'
import { useLayout } from '../../core';
import { MutableRefObject, useEffect, useRef } from 'react';
import { ToggleComponent } from '../../../assets/ts/components';
import clsx from 'clsx';


type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>
}
const SidebarFooter = (props: PropsType) => {
  const { config } = useLayout();
  const navigate = useNavigate();
  function navigateToAi() {
    navigate("/ask-ai")

  }
  const toggleRef = useRef<HTMLDivElement>(null)


  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
      ? 'minimize'
      : ''
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : ''
  const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(toggleRef.current!) as ToggleComponent | null

      if (toggleObj === null) {
        return
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating')
        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating')
        }, 300)
      })
    }, 600)
  }, [toggleRef, props.sidebarRef])


  return (
    <div className='app-sidebar-footer flex-column-auto align-items-center pt-2 pb-6 px-6 app-sidebar-logo px-6' id='kt_app_sidebar_logo'>

      <Link to='/dashboard'>
        {config.layoutType === 'dark-sidebar' ? (
          // <img
          //   alt='Logo'
          //   src={toAbsoluteUrl('/media/logos/default-dark.svg')}
          //   className='h-25px app-sidebar-logo-default'
          // />hrm-logo.svg
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default.svg')}
            className='h-35px app-sidebar-logo-default'
          />
        ) : (
          <>
            {/* <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className='h-25px app-sidebar-logo-default theme-light-show'
            /> */}
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className='h-25px app-sidebar-logo-default theme-light-show'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className='h-25px app-sidebar-logo-default theme-light-show'
            />

            {/* <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default-dark.svg')}
              className='h-25px app-sidebar-logo-default theme-dark-show'
              style={{marginLeft: '6px'}}
            /> */}
          </>
        )}

        {/* <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/favicon.png')}
          className='h-25px app-sidebar-logo-minimize'
          style={{marginLeft: '6px'}}
        /> */}

        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/logos/favicon.png')}
          className='h-25px app-sidebar-logo-minimize'
          style={{ marginLeft: '6px' }}
        />
      </Link>

     
    </div>
  )
}

export { SidebarFooter }
