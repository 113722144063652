import {createAsyncThunk, createSlice, isAnyOf} from '@reduxjs/toolkit'

import {
  createDesignation,
  getDesignationList,
  getDesignationById,
  updateDesignation,
  deleteDesignation,
} from '../../services/DesignationsService'

interface GetDesignationListParams {
  pageNumber?: number
  pageSize?: number
  filterkey?: any
  isDropdown?: number
}

interface DesignationState {
  designationList: any
  designationData: any
  createDesignationData: any
  updateDesignationData: any
}

const initialState: DesignationState = {
  designationList: null,
  designationData: null,
  createDesignationData: null,
  updateDesignationData: null,
}

export const createDesignationApi = createAsyncThunk(
  'create-Designation/api/post',

  async (payload: any, {rejectWithValue}) => {
    try {
      const result = await createDesignation(payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getDesignationApi = createAsyncThunk(
  'DesignationList/api/get',
  async (
    {pageNumber, pageSize, filterkey, isDropdown}: GetDesignationListParams,
    {rejectWithValue, fulfillWithValue}
  ) => {
    try {
      const result = await getDesignationList(pageNumber, pageSize, filterkey, isDropdown)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const getDesignationByIdApi = createAsyncThunk(
  'DesignationById/api/get',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await getDesignationById(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const updateDesignationApi = createAsyncThunk(
  'updateDesignation/api/put',

  async ({id, payload}: {id: any; payload: any}, {rejectWithValue}) => {
    try {
      const result = await updateDesignation(id, payload)
      return result
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

export const deleteDesignationApi = createAsyncThunk(
  'Designation/api/delete',
  async (id: any, {rejectWithValue, fulfillWithValue}) => {
    try {
      const result = await deleteDesignation(id)
      return result.data
    } catch (error) {
      return rejectWithValue({error})
    }
  }
)

const RoleSlice = createSlice({
  name: 'Role',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getDesignationApi.fulfilled, (state, action) => {
        state.designationList = action.payload
      })
      .addCase(getDesignationByIdApi.fulfilled, (state, action) => {
        state.designationData = action.payload
      })
      .addCase(createDesignationApi.fulfilled, (state, action) => {
        console.log(action?.payload?.data)
        state.createDesignationData = action?.payload
      })
      .addCase(updateDesignationApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateDesignationData = action?.payload
      })
      .addCase(deleteDesignationApi.fulfilled, (state, action) => {
        console.log(action?.payload)
        state.updateDesignationData = action?.payload
      })
    //   designationList: any
    //   designationData: any
    //   createDesignationData: any
    //   updateDesignationData: any

    //   createDesignationApi
    //   getDesignationApi
    //   getDesignationByIdApi
    //   updateDesignationApi
    //   deleteDepartmentApi
  },
})
export default RoleSlice
